import React, { useState } from 'react';
import { DownloadOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Input, Layout, Row, Space, Typography } from '@jll/react-ui-components';

import { MarketDetail } from 'api/marketApi';
import DataFilesDialog from 'components/dataFiles/DataFilesDialog';
import MarketDetailsEntryDialog from 'components/marketEntry/MarketDetailsEntryDialog';
import MarketList from 'components/marketList/MarketList';

import styles from './AdminPage.module.scss';

const { Header, Content } = Layout;

const AdminPage = () => {
    const [searchText, setSearchText] = useState('');
    const [dataFilesDialogOpen, setDataFilesDialogOpen] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const handleDataFiles = () => {
        setDataFilesDialogOpen(true);
    };

    const handleCreateMarket = () => {
        setShowDialog(true);
    };

    const closeAddMarketModal = () => {
        setShowDialog(false);
    };

    const handleDataFilesDialogClose = () => {
        setDataFilesDialogOpen(false);
    };

    const handleOnSearch = (event: React.FormEvent<HTMLInputElement>) => {
        setSearchText(event.currentTarget.value);
    };

    return (
        <Layout>
            <Header
                style={{
                    borderBottom: '1px solid #ddd',
                    background: '#fafafa',
                }}
            >
                <Row justify='space-between' align='bottom'>
                    <Col>
                        <Typography.Title level={2}>Markets</Typography.Title>
                    </Col>
                    <Col>
                        <Input
                            className={styles.searchMarkets}
                            placeholder='Search Markets'
                            onChange={handleOnSearch}
                        />
                    </Col>
                    <Col>
                        {dataFilesDialogOpen && (
                            <DataFilesDialog onClose={handleDataFilesDialogClose} />
                        )}
                        {showDialog && (
                            <MarketDetailsEntryDialog
                                market={{} as MarketDetail}
                                lastAction='add'
                                closeDialog={closeAddMarketModal}
                            />
                        )}
                        <Space>
                            <Button
                                type='link'
                                icon={<DownloadOutlined />}
                                onClick={handleDataFiles}
                            >
                                Data Files
                            </Button>
                            <Button
                                type='primary'
                                icon={<PlusCircleOutlined />}
                                onClick={handleCreateMarket}
                            >
                                Create Market
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Header>
            <Content>
                <MarketList searchText={searchText} />
            </Content>
        </Layout>
    );
};

export default AdminPage;
