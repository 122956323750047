import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { Switch, TableColumnsType, TablePaginationConfig } from '@jll/react-ui-components';
import {
    Col,
    Input,
    Layout,
    Pagination,
    Radio,
    Row,
    SorterResult,
    Space,
    Table,
    Typography,
} from '@jll/react-ui-components';
import formatDate from 'date-fns/format';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNil } from 'lodash';
import debounce from 'lodash/debounce';

import { PresentationSummary, searchPresentations } from 'api/presentationApi';
import MarketSelect, { MarketSummaryOption } from 'components/MarketSelect';
import PresentationImage from 'components/presentations/PresentationImage';
import { SHARED_STATE_STRINGS, SharedStateString } from 'enums/SharedState';
import { getPresentationUrl } from 'utils/apiClient/urlUtil';
import { getDisplayNameFromUpn } from 'utils/userUtils';
import { PresentationCards } from './PresentationCards';
import PresentationContextMenu from './PresentationContextMenu';
import PresentationLibraryFavorite from './PresentationFavorite';

import styles from './PresentationLibrary.module.scss';

import GridIcon from 'res/icons/grid.svg';
import GroupIcon from 'res/icons/group.svg';
import ListIcon from 'res/icons/list.svg';
import LockedIcon from 'res/icons/locked.svg';

const { Search } = Input;
const { Content } = Layout;

interface PresentationLibraryTableTitleProps {
    presentationName: string;
    presentationId: number;
    modifiedDate: Date;
    clientVersion: number;
    presentationFavoriteState: boolean;
    onFavoriteStateChange: () => void;
}

function PresentationLibraryTableTitle({
    presentationId,
    presentationName,
    modifiedDate,
    clientVersion,
    presentationFavoriteState,
    onFavoriteStateChange,
}: PresentationLibraryTableTitleProps): JSX.Element {
    const presentationUrl = getPresentationUrl(presentationId, clientVersion);

    return (
        <a href={presentationUrl}>
            <Row align='middle' style={{ gap: 8, flexWrap: 'nowrap' }}>
                <div className={styles.presentationTableThumbnail}>
                    <PresentationImage
                        presentationId={presentationId}
                        modifiedDate={modifiedDate}
                    />
                </div>
                <Typography.Text ellipsis={{ tooltip: true }}>
                    {presentationName}
                    <PresentationLibraryFavorite
                        presentationId={presentationId}
                        isFavorite={presentationFavoriteState}
                        onChange={onFavoriteStateChange}
                    />
                </Typography.Text>
            </Row>
        </a>
    );
}

interface AccessTypeIndicatorProps {
    presentationSummary: PresentationSummary;
}

function AccessTypeIndicator(props: AccessTypeIndicatorProps) {
    return props.presentationSummary.isShared || props.presentationSummary.isPublished ? (
        <GroupIcon style={{ fill: 'currentcolor' }} />
    ) : (
        <LockedIcon style={{ fill: 'currentcolor' }} />
    );
}

async function getPresentations(
    ownerType: SharedStateString,
    marketIds: string,
    page: number,
    limit: number,
    searchString: string,
    sorters?: SorterResult<PresentationSummary> | SorterResult<PresentationSummary>[],
    isDefaultPresentation?: boolean,
    arcgisOnly?: boolean
) {
    return searchPresentations({
        sharedState: ownerType,
        marketIds,
        searchQuery: searchString,
        offset: (page - 1) * limit,
        limit,
        orderBy: getOrderByFilter(sorters),
        ...(isDefaultPresentation && { isDefaultPresentation }),
        ...(arcgisOnly && { arcgisOnly }),
    });
}

function getOrderByFilter(
    sorters?: SorterResult<PresentationSummary> | SorterResult<PresentationSummary>[]
) {
    if (!sorters || Array.isArray(sorters) || !sorters.columnKey || !sorters.order) {
        return undefined;
    }

    return `${sorters.columnKey} ${sorters.order === 'ascend' ? 'asc' : 'desc'}`;
}

function validSharedStateOrAll(sharedState: string | undefined): SharedStateString {
    if (!sharedState) {
        return 'All';
    }
    return (
        SHARED_STATE_STRINGS.find((state) => state.toUpperCase() === sharedState.toUpperCase()) ||
        'All'
    );
}

enum PresentationLibraryDisplayMode {
    List = 1,
    Grid = 2,
}

const gridDisplayTypeOptions = [
    { label: <Icon component={ListIcon} />, value: PresentationLibraryDisplayMode.List },
    { label: <Icon component={GridIcon} />, value: PresentationLibraryDisplayMode.Grid },
];

export default function PresentationLibrary(): JSX.Element {
    const { globalMarkets: arcgisPresentationsEnabled = false } = useFlags();
    const [searchString, setSearchString] = useState('');
    const [data, setData] = useState<PresentationSummary[]>([]);
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedMarkets, setSelectedMarkets] = useState<MarketSummaryOption[]>([]);
    const [seeMarketOverviews, setSeeMarketOverviews] = useState<boolean>(false);
    const [arcgisOnly, setArcgisOnly] = useState(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
    });
    const { current: currentPage, pageSize } = pagination;
    const [sorters, setSorters] = useState<
        SorterResult<PresentationSummary> | SorterResult<PresentationSummary>[]
    >();
    const params = useParams<'page'>();
    const page: SharedStateString = validSharedStateOrAll(params.page);

    const columns: TableColumnsType<PresentationSummary> = [
        {
            title: 'Name',
            dataIndex: 'presentationName',
            key: 'PresentationName',
            sorter: true,
            render: (text, presentationSummary) => (
                <Row align='middle' style={{ gap: '16px' }}>
                    <PresentationLibraryTableTitle
                        presentationId={presentationSummary.id}
                        presentationName={text}
                        modifiedDate={presentationSummary.modifiedDate}
                        clientVersion={presentationSummary.clientVersion}
                        presentationFavoriteState={presentationSummary.isFavorite}
                        onFavoriteStateChange={reload}
                    />
                </Row>
            ),
            width: 'auto',
        },
        {
            title: 'Access',
            dataIndex: 'accessType',
            key: 'AccessType',
            align: 'center',
            sorter: true,
            render: (_value, presentationSummary) => (
                <AccessTypeIndicator presentationSummary={presentationSummary} />
            ),
            width: '48px',
        },
        {
            title: 'Market',
            dataIndex: 'marketName',
            key: 'MarketName',
            sorter: true,
            width: '200px',
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'CreatedBy',
            sorter: true,
            render: (upn) => getDisplayNameFromUpn(upn),
            width: '150px',
        },
        {
            title: 'Last Modified',
            dataIndex: 'modifiedDate',
            key: 'ModifiedDate',
            sorter: true,
            render: (date) => formatDate(date, 'd LLL y'),
            width: '150px',
        },
        {
            title: 'Date Created',
            dataIndex: 'createdDate',
            key: 'CreatedDate',
            sorter: true,
            render: (date) => formatDate(date, 'd LLL y'),
            width: '200px',
        },
        {
            title: 'Actions',
            render: (_text, presentationSummary) => {
                return (
                    <PresentationContextMenu
                        presentationId={presentationSummary.id}
                        presentationName={presentationSummary.presentationName}
                        onComplete={reload}
                        buttonProps={{
                            children: 'Actions',
                        }}
                        accessType={presentationSummary.accessType}
                    />
                );
            },
            width: '48px',
        },
    ];

    const updateSearchString = useMemo(() => {
        return debounce(setSearchString, 200);
    }, []);

    const reload = useCallback(async () => {
        setLoading(true);
        const searchResult = await getPresentations(
            page,
            selectedMarkets.map((item) => item.value).join(','),
            currentPage || 0,
            pageSize || 20,
            searchString,
            sorters,
            seeMarketOverviews,
            arcgisOnly
        );
        setData(searchResult.presentationSummaries);
        setPagination((pagination) => ({
            ...pagination,
            total: searchResult.total,
        }));

        setLoading(false);
    }, [
        page,
        currentPage,
        pageSize,
        sorters,
        searchString,
        selectedMarkets,
        seeMarketOverviews,
        arcgisOnly,
    ]);

    useEffect(() => {
        const { total } = pagination;
        if (isNil(currentPage) || isNil(pageSize) || isNil(total)) return;

        if (total && currentPage > Math.ceil(total / pageSize)) {
            setPagination((pagination) => ({
                ...pagination,
                current: Math.ceil(total / pageSize),
            }));
        }
    }, [pagination.total, currentPage, pageSize, pagination]);

    useEffect(() => {
        reload();
    }, [reload]);

    const displayMode = searchParams.has('displayMode')
        ? parseInt(searchParams.get('displayMode') ?? '')
        : PresentationLibraryDisplayMode.List;

    const presentationList =
        displayMode === PresentationLibraryDisplayMode.List ? (
            <Table
                style={{ marginTop: '12px' }}
                columns={columns}
                rowKey='id'
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={(pagination, _filters, sorters) => {
                    setPagination(pagination);
                    setSorters(sorters);
                }}
            />
        ) : (
            <PresentationCards
                reload={reload}
                loading={loading}
                presentationSummaries={data}
                onChange={(page, pageSize) => {
                    setPagination({ ...pagination, pageSize, current: page });
                }}
                pagination={pagination}
            />
        );

    return (
        <Content>
            <Col style={{ width: '100%' }}>
                <Row gutter={8} wrap={false} align='middle'>
                    <Col>
                        <Search
                            id='presentation-library__search'
                            style={{ minWidth: '100px' }}
                            placeholder='Search by presentation name'
                            onChange={(e) => updateSearchString(e.currentTarget.value)}
                        />
                    </Col>
                    <Col>
                        <MarketSelect
                            id='presentation-library__market-select'
                            getPopupContainer={() =>
                                document.getElementById('main-content') || document.body
                            }
                            mode='multiple'
                            allowClear
                            maxTagCount='responsive'
                            value={selectedMarkets}
                            onChange={(value) => {
                                setSelectedMarkets(value as MarketSummaryOption[]);
                            }}
                            placeholder='Filter by market'
                            style={{ width: '350px' }}
                        />
                    </Col>
                    <Col>
                        <Switch
                            onChange={(checked: boolean) => {
                                setSeeMarketOverviews(checked);
                            }}
                        />
                        <Typography.Text
                            style={{
                                marginLeft: '5px',
                            }}
                        >
                            See Off the Shelf Presentations
                        </Typography.Text>
                    </Col>
                    {arcgisPresentationsEnabled && (
                        <Col>
                            <Switch onChange={(checked: boolean) => setArcgisOnly(checked)} />
                            <Typography.Text
                                style={{
                                    marginLeft: '5px',
                                }}
                            >
                                New Blackbird Presentation
                            </Typography.Text>
                        </Col>
                    )}
                    <Col style={{ marginLeft: 'auto' }}>
                        <Space style={{ marginLeft: 'auto' }}>
                            <Pagination
                                style={{ whiteSpace: 'nowrap' }}
                                {...pagination}
                                simple
                                onChange={(page, pageSize) => {
                                    setPagination({ ...pagination, pageSize, current: page });
                                }}
                            />
                            <Radio.Group
                                id='presentation-library__display-type-buttons'
                                style={{ whiteSpace: 'nowrap' }}
                                options={gridDisplayTypeOptions}
                                onChange={(e) => {
                                    setSearchParams({
                                        ...searchParams,
                                        displayMode: e.target.value,
                                    });
                                }}
                                value={displayMode}
                                optionType='button'
                                buttonStyle='solid'
                            />
                        </Space>
                    </Col>
                </Row>
                {presentationList}
            </Col>
        </Content>
    );
}
