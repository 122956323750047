import { useState } from 'react';
import { Input, message as Message, Modal } from '@jll/react-ui-components';

import { sendEvent } from 'utils/analyticsUtils';
import endpoints from 'utils/apiClient/endpoints';
import { PresentationContextMenuItemProps } from './PresentationContextMenuProps';

export function RenamePresentationModal({
    presentationId,
    presentationName,
    onOk,
    onModalClosed,
}: PresentationContextMenuItemProps): JSX.Element {
    const [visible, setVisible] = useState(true);
    const [name, setName] = useState(presentationName);

    const handleOk = async () => {
        const result = await endpoints.presentations.rename.post({
            templateValues: { presentationId },
            fetchOptions: { body: JSON.stringify(name) },
        });

        if (result.ok !== undefined && !result.ok) {
            console.error(result);
            Message.error('failed to rename presentation');
            return null;
        }

        Message.success('Successfully renamed presentation');

        sendEvent({
            category: 'File',
            action: 'Rename',
            label: name,
        });

        setVisible(false);
        onOk();
    };

    return (
        <div>
            <Modal
                title='Rename presentation'
                okText='Rename'
                onOk={handleOk}
                onCancel={() => setVisible(false)}
                centered
                open={visible}
                destroyOnClose={true}
                afterClose={onModalClosed}
            >
                <div>
                    <label>Enter a name for the presentation</label>
                    <Input
                        style={{ marginTop: '16px' }}
                        value={name}
                        onChange={(e) => setName(e.currentTarget.value)}
                    />
                </div>
            </Modal>
        </div>
    );
}
