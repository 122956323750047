import type { TablePaginationConfig } from '@jll/react-ui-components';
import { Empty, Pagination, Spin } from '@jll/react-ui-components';

import { PresentationSummary } from 'api/presentationApi';
import PresentationCard from './PresentationCard';

import styles from './PresentationCards.module.scss';

export interface PresentationCardsProps {
    reload: () => void;
    loading: boolean;
    presentationSummaries: PresentationSummary[];
    onChange: (page: number, pageSize: number) => void;
    pagination: TablePaginationConfig;
}

export function PresentationCards({
    reload,
    loading,
    onChange,
    pagination,
    presentationSummaries,
}: PresentationCardsProps): JSX.Element {
    if (presentationSummaries.length === 0)
        return <Empty className={styles.emptyResults} image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    return (
        <>
            <Spin spinning={loading}>
                <div className={styles.grid}>
                    {presentationSummaries.map((item) => {
                        return <PresentationCard key={item.id} reload={reload} {...item} />;
                    })}
                </div>
                <Pagination className={styles.pagination} onChange={onChange} {...pagination} />
            </Spin>
        </>
    );
}
