import { ReactNode, useState } from 'react';
import { Alert, Button, Form, Input, Spin } from '@jll/react-ui-components';
import { Rule } from 'antd/lib/form';

import endpoints from 'utils/apiClient/endpoints';
import {
    getClientUserOneTimeToken,
    getShareTokenLoginData,
} from 'utils/apiClient/shareLinkSessionStorage';
import { VALIDATE_MESSAGES } from 'utils/formValidation';
import { navigateToPage } from './signup/navigateToPage';
import {
    getErrorFromErrorResponse,
    getMessageFromErrorResponse,
    WrappedError,
} from './signup/SignupForm';

interface FormValues {
    email: string;
}

interface FieldConfig {
    name: keyof FormValues;
    label: string;
    rules?: Rule[];
}
const FIELD_ENTRIES: FieldConfig[] = [
    { name: 'email', label: 'Email', rules: [{ required: true, type: 'email', whitespace: true }] },
];

const FIELD_TABLE = Object.fromEntries(
    FIELD_ENTRIES.map((entry) => [entry.name, entry] as [FieldConfig['name'], FieldConfig])
) as Record<FieldConfig['name'], FieldConfig>;

const UNKNOWN_ERROR_MESSAGE = 'An unknown error occurred during the access';

async function trackMagicLinkPost(values: FormValues): Promise<void> {
    try {
        await endpoints.magicLink.track.post({
            fetchOptions: {
                body: JSON.stringify({
                    ...values,
                    token: getClientUserOneTimeToken(),
                }),
            },
        });
    } catch (e) {
        throw new WrappedError(getMessageFromErrorResponse(e) ?? UNKNOWN_ERROR_MESSAGE, e);
    }
    try {
        const result = getShareTokenLoginData() ?? {};

        if (typeof result.presentationId !== 'number') {
            throw new Error(`Invalid presentation id: ${result.presentationId}`);
        }
        navigateToPage(`/index.html?P=${result.presentationId}`);
    } catch (e) {
        throw new WrappedError('Error redirecting to presentation', e);
    }
}

export function MagicLinkForm(): JSX.Element {
    const [error, setError] = useState<ReactNode | undefined>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

    const trackMagicLink = async (values: FormValues): Promise<void> => {
        setIsSaving(true);
        try {
            await trackMagicLinkPost(values);
            setError(undefined);
            setIsRedirecting(true);
        } catch (error) {
            setError(getErrorFromErrorResponse(error) || UNKNOWN_ERROR_MESSAGE);
            setIsRedirecting(false);
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <Spin
            spinning={isSaving || isRedirecting}
            tip={isSaving ? 'Saving email...' : 'Redirecting...'}
        >
            {error && (
                <Alert
                    type='error'
                    message='Error saving email address'
                    description={error}
                    showIcon
                />
            )}
            <Form<FormValues>
                name='magiclinkform'
                layout='vertical'
                requiredMark={false}
                onFinish={trackMagicLink}
                disabled={isSaving}
                validateMessages={VALIDATE_MESSAGES}
            >
                <Form.Item<FormValues> {...FIELD_TABLE['email']}>
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit' style={{ width: '100%' }}>
                        See presentation
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}
