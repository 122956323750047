import { useState } from 'react';
import { Layout, Typography } from '@jll/react-ui-components';

import DraggableModal from 'components/DraggableModal';
import {
    CLIENT_PRESENTATION_USE_LIMIT,
    getClientUserMagicLinkState,
    getShareTokenUseCount,
} from 'utils/apiClient/shareLinkSessionStorage';
import { MagicLinkView } from './views/MagicLinkView';
import { SignUpView } from './views/SignUpView';

import styles from './SignupPage.module.scss';

import BlackbirdLogo from 'images/blackbird-logo-negative.svg';
import JllLogo from 'images/jll-logo-red-and-black.svg';

export default function SignupPage(): JSX.Element {
    const usesRemaining = CLIENT_PRESENTATION_USE_LIMIT - (getShareTokenUseCount() ?? 0);
    const isMagicLink = getClientUserMagicLinkState();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>('');
    const [modalContent, setModalContent] = useState('');

    const handleModal = (type: 'Terms of Service' | 'Privacy Policy') => {
        setModalTitle(type);
        //This is just a placeholder for content. We wil be updated when we have the full text.
        setModalContent(
            type === 'Terms of Service' ? 'General Terms of Service' : 'General Privacy Policy'
        );
        setIsModalOpen(true);
    };

    return (
        <>
            <DraggableModal
                title={modalTitle}
                open={isModalOpen}
                width={650}
                onCancel={() => setIsModalOpen(false)}
                mask={true}
                maskClosable={true}
                footer={null}
            >
                {modalContent}
            </DraggableModal>

            <Layout style={{ width: '100%', minHeight: '100%', fontSize: 16 }}>
                <Layout.Header style={{ backgroundColor: 'white' }}>
                    <div className={styles.signupPageHeader}>
                        <BlackbirdLogo className={styles.signupPageBlackbirdLogo} />
                    </div>
                </Layout.Header>
                <Layout.Content
                    className={styles.signupPageContentContainer}
                    style={{ backgroundColor: 'white' }}
                >
                    <div className={styles.signupPageContent}>
                        {isMagicLink ? (
                            <MagicLinkView />
                        ) : (
                            <SignUpView usesRemaining={usesRemaining} />
                        )}
                    </div>
                </Layout.Content>
                <Layout.Footer style={{ backgroundColor: 'white' }}>
                    <div className={styles.signupPageFooter}>
                        <div style={{ fontSize: 16 }}>
                            By proceeding, you agree to our{' '}
                            <Typography.Link
                                style={{
                                    color: '#1890ff',
                                }}
                                onClick={() => handleModal('Terms of Service')}
                            >
                                Terms of Service
                            </Typography.Link>{' '}
                            and{' '}
                            <Typography.Link
                                style={{
                                    color: '#1890ff',
                                }}
                                onClick={() => handleModal('Privacy Policy')}
                            >
                                Privacy Policy
                            </Typography.Link>
                            .
                        </div>
                        <JllLogo className={styles.signupPageJllLogo} />
                    </div>
                </Layout.Footer>
            </Layout>
        </>
    );
}
