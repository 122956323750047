export interface PresentationContextMenuProps {
    presentationId: number;
    presentationName: string;
    onComplete(): void;
    buttonProps?: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
        children?: any;
        className?: string;
        size?: string;
    };
    accessType?: number;
}

export interface PresentationContextMenuItemProps {
    presentationId: number;
    presentationName: string;
    onOk: () => void;
    onModalClosed: () => void;
}

export enum ContextMenuActions {
    FEATURED = 'presentation_library_featured',
    COPY = 'presentation_library_copy',
    RENAME = 'presentation_library_rename',
    DELETE = 'presentation_library_delete',
}
