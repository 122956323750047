import { ReactNode, useState } from 'react';
import { Button, Form, Input, Spin } from '@jll/react-ui-components';
import { Alert, FormRule } from '@jll/react-ui-components';

import { ApiEndpointError } from 'utils/apiClient/ApiEndpoint';
import endpoints from 'utils/apiClient/endpoints';
import {
    getClientUserOneTimeToken,
    getShareTokenLoginData,
    setUserSignupStatus,
} from 'utils/apiClient/shareLinkSessionStorage';
import { PASSWORD_CHARSET_RULES, VALIDATE_MESSAGES } from 'utils/formValidation';
import { navigateToPage } from './navigateToPage';

const UNKNOWN_ERROR_MESSAGE = 'An unknown error occurred during registration';

interface FormValues {
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    password: string;
}

export class WrappedError<T> extends Error {
    innerError: T;

    constructor(message: string, innerError: T, options?: ErrorOptions) {
        super(message, options);
        this.name = 'WrappedError';
        this.innerError = innerError;
    }
}

async function registerAccount(values: FormValues): Promise<void> {
    try {
        await endpoints.signup.withShareToken.post({
            fetchOptions: {
                body: JSON.stringify({
                    ...values,
                    shareToken: getClientUserOneTimeToken(),
                }),
            },
        });
    } catch (e) {
        throw new WrappedError(getMessageFromErrorResponse(e) ?? UNKNOWN_ERROR_MESSAGE, e);
    }

    // Store signup info for hinting away from asking the user to sign up again
    setUserSignupStatus({
        signedUp: true,
        userName: values.email,
        signupTime: new Date(),
    });

    try {
        const result = getShareTokenLoginData() ?? {};

        if (typeof result.presentationId !== 'number') {
            throw new Error(`Invalid presentation id: ${result.presentationId}`);
        }
        navigateToPage(`/index.html?P=${result.presentationId}`);
    } catch (e) {
        throw new WrappedError('Error redirecting to presentation', e);
    }
}

export function getMessageFromErrorResponse(error: unknown) {
    if (!(error instanceof ApiEndpointError)) {
        return;
    }

    return (error.errorResponse as { title?: string } | undefined)?.title;
}

interface ServerValidationErrors {
    errors: Record<string, undefined | string[]>;
    title: string;
}
export function getErrorFromErrorResponse(wrappedError: unknown): ReactNode | undefined {
    if (!(wrappedError instanceof WrappedError)) {
        return;
    }
    const innerError = wrappedError.innerError;

    if (!(innerError instanceof ApiEndpointError)) {
        return wrappedError.message;
    }

    const errorResponse = innerError.errorResponse as Partial<ServerValidationErrors> | undefined;
    if (!errorResponse) {
        return (innerError as { message?: string }).message;
    }
    const message = getErrorTitleFromResponse(errorResponse);
    const errorEntries = getErrorItemsFromResponse(errorResponse);

    if (!errorEntries && !message) {
        return;
    }

    return (
        <>
            {message && <p>{message}</p>}
            {errorEntries && (
                <ul>
                    {errorEntries.map(([propertyName, errors]) => {
                        return (
                            <>
                                <li key={propertyName}>
                                    {FIELD_ENTRIES.find(
                                        (entry) =>
                                            entry.name.toUpperCase() === propertyName.toUpperCase()
                                    )?.label ?? propertyName}
                                </li>
                                {errors && errors.length > 0 ? (
                                    <ul>
                                        {errors.map((error, index) => (
                                            <li key={index}>{error}</li>
                                        ))}
                                    </ul>
                                ) : null}
                            </>
                        );
                    })}
                </ul>
            )}
        </>
    );
}

function getErrorTitleFromResponse(errorResponse: Partial<ServerValidationErrors>) {
    return errorResponse?.title || (errorResponse as { message: string | undefined })?.message;
}
function getErrorItemsFromResponse(errorResponse: Partial<ServerValidationErrors>) {
    if (!errorResponse || !errorResponse.errors) {
        return;
    }

    const errorEntries = Object.entries(errorResponse.errors);

    if (errorEntries.length === 0) {
        return;
    }

    return errorEntries;
}

interface FieldConfig {
    name: keyof FormValues;
    label: string;
    rules?: FormRule[];
}
const FIELD_ENTRIES: FieldConfig[] = [
    { name: 'email', label: 'Email', rules: [{ required: true, type: 'email', whitespace: true }] },
    { name: 'firstName', label: 'First name', rules: [{ required: true, whitespace: true }] },
    { name: 'lastName', label: 'Last name', rules: [{ required: true, whitespace: true }] },
    { name: 'company', label: 'Company', rules: [{ required: true, whitespace: true }] },
    {
        name: 'password',
        label: 'Password',
        rules: [{ required: true, min: 8, whitespace: true }, ...PASSWORD_CHARSET_RULES],
    },
];
const FIELD_TABLE = Object.fromEntries(
    FIELD_ENTRIES.map((entry) => [entry.name, entry] as [FieldConfig['name'], FieldConfig])
) as Record<FieldConfig['name'], FieldConfig>;

export function SignupForm(): JSX.Element {
    const [registrationError, setRegistrationError] = useState<ReactNode | undefined>();
    const [isRegistering, setIsRegistering] = useState(false);

    const registerAction = async (values: FormValues): Promise<void> => {
        setIsRegistering(true);

        try {
            await registerAccount(values);
            setRegistrationError(undefined);
        } catch (error) {
            setRegistrationError(getErrorFromErrorResponse(error) || UNKNOWN_ERROR_MESSAGE);
        } finally {
            setIsRegistering(false);
        }
    };

    return (
        <Spin spinning={isRegistering} tip='Registering account'>
            {registrationError && (
                <Alert
                    type='error'
                    message='Error registering account'
                    description={registrationError}
                    showIcon
                />
            )}
            <Form<FormValues>
                name='signupform'
                layout='vertical'
                requiredMark={false}
                onFinish={registerAction}
                disabled={isRegistering}
                validateMessages={VALIDATE_MESSAGES}
            >
                <Form.Item<FormValues> {...FIELD_TABLE['firstName']}>
                    <Input />
                </Form.Item>
                <Form.Item<FormValues> {...FIELD_TABLE['lastName']}>
                    <Input />
                </Form.Item>
                <Form.Item<FormValues> {...FIELD_TABLE['company']}>
                    <Input />
                </Form.Item>
                <Form.Item<FormValues> {...FIELD_TABLE['email']}>
                    <Input />
                </Form.Item>
                <Form.Item<FormValues> {...FIELD_TABLE['password']}>
                    <Input.Password />
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit' style={{ width: '100%' }}>
                        Register account
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}
