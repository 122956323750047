import { ReactElement, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { MenuOutlined, SolutionOutlined } from '@ant-design/icons';
import { Layout } from '@jll/react-ui-components';

import FeaturedPresentations from 'components/presentations/FeaturedPresentations';
import RecentlySharedPresentations from 'components/presentations/RecentlySharedPresentations';
import RecentlyViewedPresentations from 'components/presentations/RecentlyViewedPresentations';
import QuickStart from 'components/quickStart/QuickStart';
import HereToSupportYou from 'components/welcome/HereToSupportYou';
import LeftPanel from 'components/welcome/LeftPanel';
import { clearPresentation } from 'store/presentationSlice';
import { useAppDispatch } from 'types/hooks';
import PresentationLibrary from './presentation/PresentationLibrary';

import styles from './Welcome.module.scss';

const { Sider, Content } = Layout;

export default function Welcome(): ReactElement {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(clearPresentation());
    }, [dispatch]);

    return (
        <Layout>
            <Sider
                className={styles.leftPanel}
                width={344}
                breakpoint='xl'
                collapsedWidth='0'
                theme='light'
                zeroWidthTriggerStyle={{ boxShadow: '1px 1px 2px rgba(0, 0, 0, .2)' }}
                trigger={<MenuOutlined />}
            >
                <LeftPanel />
            </Sider>
            <Content id='main-content' className={styles.content}>
                <Routes>
                    <Route path='presentations' element={<PresentationLibrary />} />
                    <Route path='presentations/:page' element={<PresentationLibrary />} />
                    <Route
                        index
                        element={
                            <>
                                <QuickStart />
                                <RecentlyViewedPresentations />
                                <RecentlySharedPresentations />
                            </>
                        }
                    />
                </Routes>
            </Content>
            <Sider
                className={styles.rightPanel}
                reverseArrow
                width={344}
                breakpoint='xxl'
                collapsedWidth='0'
                theme='light'
                zeroWidthTriggerStyle={{ boxShadow: '-1px 1px 2px rgba(0, 0, 0, .2)' }}
                trigger={<SolutionOutlined />}
            >
                <FeaturedPresentations style={{ maxHeight: '70%' }} />
                <HereToSupportYou style={{ maxHeight: '30%' }} />
            </Sider>
        </Layout>
    );
}
