import { EllipsisOutlined } from '@ant-design/icons';
import { Card, Row, Typography } from '@jll/react-ui-components';
import format from 'date-fns/format';

import PresentationImage from 'components/presentations/PresentationImage';
import { getPresentationUrl } from 'utils/apiClient/urlUtil';
import { emailToDisplayName } from 'utils/emailUtils';
import PresentationContextMenu from './PresentationContextMenu';
import PresentationLibraryFavorite from './PresentationFavorite';

import styles from './PresentationCard.module.scss';

const { Title, Text } = Typography;

export interface PresentationCardProps {
    id: number;
    presentationName: string;
    marketName: string;
    isFavorite: boolean;
    createdDate: Date;
    modifiedDate: Date;
    createdBy: string;
    clientVersion: number;
    reload: () => void;
}

export default function PresentationCard({
    id,
    presentationName,
    marketName,
    isFavorite,
    createdDate,
    modifiedDate,
    createdBy,
    clientVersion,
    reload,
}: PresentationCardProps): JSX.Element {
    const presentationUrl = getPresentationUrl(id, clientVersion);

    return (
        <a href={presentationUrl}>
            <Card
                className={styles.card}
                hoverable
                cover={
                    <div style={{ height: '142px' }}>
                        <PresentationImage presentationId={id} modifiedDate={modifiedDate} />
                    </div>
                }
                style={{ padding: 0, borderRadius: '8px' }}
                key={id}
            >
                <PresentationContextMenu
                    presentationId={id}
                    presentationName={presentationName}
                    onComplete={reload}
                    buttonProps={{
                        children: <EllipsisOutlined />,
                        className: styles.menu,
                        size: 'small',
                    }}
                />
                <Text type='secondary' ellipsis={{ tooltip: true }}>
                    {marketName}
                </Text>
                <Row wrap={false}>
                    <Title ellipsis={{ tooltip: presentationName }} level={5} style={{ margin: 0 }}>
                        {presentationName}
                    </Title>
                    <PresentationLibraryFavorite
                        isFavorite={isFavorite}
                        presentationId={id}
                        onChange={reload}
                    />
                </Row>
                <Text ellipsis={{ tooltip: true }}>
                    {emailToDisplayName(createdBy)} <span> &bull; </span>
                    {format(new Date(createdDate), 'MM/dd/yyyy')}
                </Text>
            </Card>
        </a>
    );
}
