import { useParams } from 'react-router-dom';
import { Result, Typography } from '@jll/react-ui-components';

import BlackbirdLogo from 'images/blackbird-logo-negative.svg';

export const ERROR_CONFIG = {
    UNAUTHORIZED: {
        name: `unauthorized`,
        statusCode: 401,
        messageRenderer: () => (
            <span>
                The presentation you were looking for could not be found or you do not have the
                right permissions. If we can assist you in any way, feel free to reach us by email
                Contact <a href={'mailto:blackbird@jll.com'}>blackbird@jll.com</a>.
            </span>
        ),
    },
    SERVER_ERROR: {
        name: `server-error`,
        statusCode: 500,
        messageRenderer: (presentationId: string | undefined) => {
            return (
                <span>
                    Blackbird has encountered a fatal error with this presentation. Please{' '}
                    <a href={`/index.html?P=${presentationId}`}>refresh</a>. If this continues to be
                    inaccessible, please contact{' '}
                    <a href={'mailto:blackbird@jll.com'}>blackbird@jll.com</a>.
                </span>
            );
        },
    },
    PRESENTATION_NOT_FOUND: {
        name: `presentation-not-found`,
        statusCode: 404,
        messageRenderer: () => (
            <span>
                It appears that there is no Blackbird presentation at this link. Please check with
                the person who shared this link to determine if the presentation has been deleted,
                or if the link may be incorrect. Contact{' '}
                <a href={'mailto:blackbird@jll.com'}>blackbird@jll.com</a> with any questions or
                concerns.
            </span>
        ),
    },
    UNKNOWN_ERROR: {
        name: `unknown-error`,
        statusCode: 500,
        messageRenderer: () => (
            <span>
                An unknown error has occurred. Please try again or contact{' '}
                <a href={'mailto:blackbird@jll.com'}>blackbird@jll.com</a>.
            </span>
        ),
    },
};

type UnauthorizedPageProps = {
    messageRenderer?: (presentationId: string | undefined) => JSX.Element;
};

export const UnauthorizedPage = ({
    messageRenderer = () => (
        <p>
            {' '}
            You are not authorized to access this page. Contact
            <a href={'mailto:blackbird@jll.com'}>blackbird@jll.com</a>
            with any questions or concerns.
        </p>
    ),
}: UnauthorizedPageProps) => {
    const { presentationId } = useParams();
    return (
        <>
            <Result
                data-testid='unauthorized-test-id'
                icon={<BlackbirdLogo width={400} height={100} />}
            >
                <Typography.Paragraph
                    style={{
                        fontSize: '16px',
                        textAlign: 'center',
                    }}
                >
                    {messageRenderer ? messageRenderer(presentationId) : null}
                </Typography.Paragraph>
            </Result>
        </>
    );
};
