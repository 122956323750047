import React from 'react';
import { Typography } from '@jll/react-ui-components';

import { MagicLinkForm } from 'pages/MagicLinkForm';

import styles from '../SignupPage.module.scss';

export const MagicLinkView: React.FC = () => (
    <>
        <Typography.Title>Enter your email address to view the presentation</Typography.Title>
        <div className={styles.signupPageFormContainer}>
            <MagicLinkForm />
        </div>
    </>
);
