import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Result, Space, Typography } from '@jll/react-ui-components';

import { redeemClientShareToken, TokenResponseType } from 'api/clientShareApi';
import { getPresentationClientVersion } from 'api/presentationApi';
import { FullScreenSpinner } from 'components/FullScreenSpinner';
import {
    setClientUserMagicLinkState,
    shouldSkipSignupPage,
    storeOneTimeTokenInfo,
} from 'utils/apiClient/shareLinkSessionStorage';
import config from 'utils/config';
import { clientSignupEnabled } from 'utils/launchDarkly';

import BlackbirdLogo from 'images/blackbird-logo-negative.svg';

enum TokenRequestState {
    idle,
    loading,
    renewed,
    failed,
}

// TODO: Write some tests
export default function ClientShareLink(): ReactElement {
    const [tokenRequestState, setTokenRequestState] = useState(TokenRequestState.idle);
    const params = useParams<'token'>();
    const oneTimeToken = params?.token;

    // TODO: Add activity logs for login
    useEffect(() => {
        const turnInToken = async () => {
            if (!oneTimeToken) {
                setTokenRequestState(TokenRequestState.failed);
                return;
            }

            setTokenRequestState(TokenRequestState.loading);

            const result = await redeemClientShareToken(oneTimeToken);
            if (!result) {
                setTokenRequestState(TokenRequestState.failed);
                return;
            }

            storeOneTimeTokenInfo(oneTimeToken, result);
            config.accessToken = result.token?.access_token;
            setClientUserMagicLinkState(result.isMagicLink);
            switch (result.tokenResponseType) {
                case TokenResponseType.valid:
                    setTokenRequestState(TokenRequestState.idle);

                    if (
                        ((await clientSignupEnabled(true)) &&
                            result.useCount >= 2 &&
                            !shouldSkipSignupPage()) ||
                        result.isMagicLink
                    ) {
                        window.location.replace('/signup');
                    } else {
                        if ((await getPresentationClientVersion(result.presentationId)) === '1') {
                            window.location.href = `/presentation/${result.presentationId}`;
                        } else {
                            window.location.href = `index.html?P=${result.presentationId}`;
                        }
                    }
                    break;
                case TokenResponseType.renewed:
                    setTokenRequestState(TokenRequestState.renewed);
                    break;
                default:
                    break;
            }
        };

        turnInToken();
    }, [oneTimeToken]);

    switch (tokenRequestState) {
        case TokenRequestState.loading:
            return <FullScreenSpinner />;
        case TokenRequestState.renewed:
            return (
                <ContentWrap>
                    <RenewedContent />
                </ContentWrap>
            );
        case TokenRequestState.failed:
            return (
                <ContentWrap>
                    <FailedContent />
                </ContentWrap>
            );
        default:
            return <div></div>;
    }
}

function RenewedContent() {
    return (
        <Result icon={<BlackbirdLogo width={400} height={100} />}>
            <Typography.Paragraph
                style={{
                    fontSize: '16px',
                    maxWidth: '400px',
                    textAlign: 'center',
                }}
            >
                In order to provide a secure and easy experience to share Blackbird presentations, a
                refreshed link will be sent to your email. If you do not receive an email
                immediately, please be sure to check your Spam folder.
            </Typography.Paragraph>
        </Result>
    );
}

function FailedContent() {
    return (
        <Result
            title={'There was a problem processing your request'}
            icon={<BlackbirdLogo width={400} height={100} />}
        />
    );
}

function ContentWrap({ children }: { children: ReactNode }) {
    return (
        <Space
            direction='horizontal'
            style={{
                position: 'relative',
                top: '20vh',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {children}
        </Space>
    );
}
