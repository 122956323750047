import { ReactElement, useState } from 'react';
import {
    Button,
    Checkbox,
    Dropdown,
    ItemType,
    message as Message,
    Modal,
    Row,
    Typography,
} from '@jll/react-ui-components';

import { removeFromFeaturedPresentations, setAsFeaturedPresentation } from 'api/presentationApi';
import FeaturedPresentation from 'interfaces/FeaturedPresentation';
import {
    fetchFeaturedPresentations,
    selectFeaturedPresentations,
} from 'store/featuredPresentationsSlice';
import { selectCurrentUser } from 'store/userSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';
import { DeletePresentationModal } from './DeletePresentationModal';
import { ContextMenuActions, PresentationContextMenuProps } from './PresentationContextMenuProps';
import { RenamePresentationModal } from './RenamePresentationModal';

export default function PresentationContextMenu({
    presentationId,
    presentationName,
    onComplete,
    buttonProps = {},
    accessType,
}: PresentationContextMenuProps): JSX.Element {
    const dispatch = useAppDispatch();
    const featuredPresentations = useAppSelector(selectFeaturedPresentations);
    const user = useAppSelector(selectCurrentUser);

    const [selectedModal, setSelectedModal] = useState<ReactElement>();

    const [isFeatured, setIsFeatured] = useState(
        isFeaturedPresentation(featuredPresentations, presentationId)
    );

    const setModalState = (
        key: string,
        e?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>
    ) => {
        let Component;
        switch (key) {
            case ContextMenuActions.FEATURED:
                e?.preventDefault();
                handleIsFeaturedChange();
                break;
            case ContextMenuActions.COPY:
                Modal.info({
                    style: { top: '256px' },
                    width: '512px',
                    title: 'Discontinued feature',
                    content: (
                        <Typography.Paragraph>
                            Copy Presentation has been discontinued. Please open the presentation
                            and select &apos;Save As&apos; under &apos;Save Presentation&apos;.
                        </Typography.Paragraph>
                    ),
                });
                break;
            case ContextMenuActions.RENAME:
                Component = RenamePresentationModal;
                break;
            case ContextMenuActions.DELETE:
                Component = DeletePresentationModal;
                break;
            default:
                break;
        }

        if (!Component) return;

        setSelectedModal(
            <Component
                presentationId={presentationId}
                presentationName={presentationName}
                onOk={onComplete}
                onModalClosed={() => setSelectedModal(undefined)}
            />
        );
    };

    const handleIsFeaturedChange = async () => {
        const newState = !isFeatured;
        setIsFeatured(newState);

        try {
            if (newState) await setAsFeaturedPresentation(presentationId);
            else await removeFromFeaturedPresentations(presentationId);
            Message.success(`Successfully ${newState ? 'set' : 'unset'} presentation as featured`);
        } catch (error) {
            Message.error(`There was a problem setting the featured state`);
        }

        await dispatch(fetchFeaturedPresentations());
    };

    const menuItems: ItemType[] = [
        {
            label: 'Copy',
            key: ContextMenuActions.COPY,
        },
        ...((typeof accessType === 'number' && accessType > 1) || user.isAdmin
            ? [
                  {
                      label: 'Rename',
                      key: ContextMenuActions.RENAME,
                  },
                  {
                      label: 'Delete',
                      key: ContextMenuActions.DELETE,
                  },
              ]
            : []),
    ];

    if (user.isAdmin) {
        menuItems.unshift({ type: 'divider' });
        menuItems.unshift({
            label: (
                <Row style={{ alignItems: 'center' }}>
                    <Checkbox checked={isFeatured}>Featured</Checkbox>
                </Row>
            ),
            key: ContextMenuActions.FEATURED,
        });
    }

    return (
        <>
            <Dropdown
                menu={{
                    prefixCls: 'ant-dropdown-menu',
                    items: menuItems,
                    onClick: (a) => {
                        setModalState(a.key, a.domEvent);
                    },
                }}
                trigger={['click']}
            >
                <Button className={buttonProps?.className}>{buttonProps?.children}</Button>
            </Dropdown>
            {selectedModal ? selectedModal : ''}
        </>
    );
}

function isFeaturedPresentation(
    featuredPresentations: FeaturedPresentation[] | undefined,
    presentationId: number
) {
    if (!featuredPresentations || featuredPresentations.length == 0) return false;
    const _matched = featuredPresentations.find((item) => item.presentationId === presentationId);
    return _matched != undefined;
}
