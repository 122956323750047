import React from 'react';
import { Divider, Typography } from '@jll/react-ui-components';

import {
    clearOneTimeTokenAccessToken,
    CLIENT_PRESENTATION_USE_LIMIT,
    getShareTokenLoginData,
} from 'utils/apiClient/shareLinkSessionStorage';
import { SignupForm } from '../SignupForm';

import styles from '../SignupPage.module.scss';

type SignUpViewProps = {
    usesRemaining: number;
};

const goToPresentation = (type: 'signIn' | 'guest') => {
    const presentationId = getShareTokenLoginData()?.presentationId;
    switch (type) {
        case 'signIn':
            clearOneTimeTokenAccessToken();
            window.location.href = `index.html?P=${presentationId}`;
            break;
        case 'guest':
            window.location.href = `index.html?P=${presentationId}`;
            break;
    }
};

export const SignUpView: React.FC<SignUpViewProps> = ({ usesRemaining }) => (
    <>
        <Typography.Title>Create an account</Typography.Title>
        <Typography.Paragraph>
            {usesRemaining <= 0
                ? 'No views left. Register for unlimited access to Blackbird presentation.'
                : `You can view this presentation ${CLIENT_PRESENTATION_USE_LIMIT} times as a
            guest. Register a free account for unlimited access.`}
        </Typography.Paragraph>
        <div className={styles.signupPageFormContainer}>
            <SignupForm />
            <Divider />
            <div className={styles.signupPageFormLinks}>
                <div>
                    <div>Already have an account?</div>
                    <div>
                        <Typography.Link
                            style={{
                                color: '#1890ff',
                            }}
                            onClick={() => goToPresentation('signIn')}
                        >
                            Sign in
                        </Typography.Link>
                    </div>
                </div>
                <div className={styles.signupPageFormLinksRight}>
                    {usesRemaining > 0 && (
                        <>
                            <div> {usesRemaining} views left</div>
                            <div>
                                <Typography.Link
                                    style={{
                                        color: '#1890ff',
                                    }}
                                    onClick={() => goToPresentation('guest')}
                                >
                                    Proceed as a guest
                                </Typography.Link>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    </>
);
