import { useState } from 'react';
import { message as Message, Modal } from '@jll/react-ui-components';

import { sendEvent } from 'utils/analyticsUtils';
import endpoints from 'utils/apiClient/endpoints';
import { PresentationContextMenuItemProps } from './PresentationContextMenuProps';

export function DeletePresentationModal({
    presentationId,
    presentationName,
    onOk,
    onModalClosed,
}: PresentationContextMenuItemProps): JSX.Element {
    const [visible, setVisible] = useState(true);

    const handleOk = async () => {
        const result = await endpoints.presentations.delete.post({
            templateValues: { presentationId },
        });

        if (result.ok !== undefined && !result.ok) {
            console.error(result);
            Message.error('failed to delete presentation');
            return null;
        }

        Message.success('Successfully deleted presentation');

        sendEvent({
            category: 'File',
            action: 'Delete',
            label: presentationName,
        });

        setVisible(false);
        onOk();
    };

    return (
        <div>
            <Modal
                title='Delete presentation'
                okText='Delete'
                onOk={handleOk}
                onCancel={() => setVisible(false)}
                centered
                open={visible}
                destroyOnClose={true}
                afterClose={onModalClosed}
            >
                <div>
                    <label>
                        Confirm that you would like to delete: <br /> <b>${presentationName}</b>
                    </label>
                </div>
            </Modal>
        </div>
    );
}
