import { useState } from 'react';
import { message as Message } from '@jll/react-ui-components';

import endpoints from 'utils/apiClient/endpoints';

import styles from './PresentationFavorite.module.scss';

import FavoriteIcon from 'icons/custom/favorite.svg';

async function setAsFavoritePresentation(presentationId: number) {
    try {
        await endpoints.presentations.favorite.post({
            templateValues: {
                presentationId: presentationId,
            },
        });
        Message.success('Successfully set presentation as favorite');
    } catch (error) {
        Message.error('Failed to set presentation as favorite');
    }
}

async function removeFromFavoritePresentations(presentationId: number) {
    try {
        await endpoints.presentations.favorite.delete({
            templateValues: {
                presentationId: presentationId,
            },
        });
        Message.success('Successfully unset presentation as favorite');
    } catch (error) {
        Message.error('Failed to unset presentation as favorite');
    }
}

interface PresentationFavoriteProps {
    presentationId: number;
    isFavorite: boolean;
    onChange: () => void;
}

export default function PresentationLibraryFavorite({
    presentationId,
    isFavorite,
    onChange,
}: PresentationFavoriteProps): JSX.Element {
    const [favorite, setFavorite] = useState(isFavorite);

    const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        const _isFavorite = !favorite;
        setFavorite(_isFavorite);

        if (_isFavorite) setAsFavoritePresentation(presentationId);
        else removeFromFavoritePresentations(presentationId);

        onChange();
    };

    return (
        <div
            className={`presentation-library__favorite-presentation-button ${styles.container}`}
            onClick={handleOnClick}
        >
            <FavoriteIcon fill={favorite ? '#000' : 'none'} />
        </div>
    );
}
